<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Grupos</h2>

    <v-card>
      <v-card-title>
        <v-toolbar flat color="backgroundSecond">
          <v-spacer></v-spacer>
    
        </v-toolbar>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="grupos"
        class="elevation-1"
        :server-items-length="totalGrupos"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
      >
        <!-- <template v-slot:top>
          <v-toolbar flat color="backgroundSecond">
            <v-spacer></v-spacer>
            <ModalImportarCNPJ />
          </v-toolbar>
        </template> -->
      </v-data-table>
    </v-card>
  </v-main>
</template>
<script>
import AdminServices from '@/services/adminServices.js';



export default {
  name: 'Grupos',

  components: {

  },

  data() {
    return {
      totalGrupos: 0,
      options: {},
      loading: true,

      headers: [{ text: 'Nome', value: 'name' }],

      grupos: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.carregarGrid();
      },
    },
  },

  mounted() {
    this.carregarGrid();
  },

  methods: {
    async carregarGrid() {
      const params = this.pegarParamsParaRequest();

      try {
        let response = await AdminServices.group.get(params);
        let keys = [];
        let listado = [];
        let gruposResp = response.data.content;

        this.totalGrupos = response.data.totalElements;

        Object.entries(gruposResp).forEach(([key, entries]) => {
          keys.push(key);
          listado.push({
            name: entries.name,
            companies: entries.companies,
          });
        });

        this.grupos = listado;
        this.loading = false;
      } catch (e) {
        localStorage.removeItem('access_Token');
        localStorage.removeItem('refresh_Token');
        this.$router.push('/');
      }
    },

    pegarParamsParaRequest() {
      let params = {};

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (page) {
        params['page'] = page - 1;
      }

      if (itemsPerPage) {
        params['size'] = itemsPerPage;
      }
      if (sortBy.length === 1) {
        params['sort'] = sortBy[0];
        params['sort'] += ',' + (sortDesc[0] === true ? 'desc' : 'asc');
      }

      return params;
    },
  },
};
</script>
