var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Grupos"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "backgroundSecond" } },
                [_c("v-spacer")],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.grupos,
              "server-items-length": _vm.totalGrupos,
              loading: _vm.loading,
              options: _vm.options,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
            },
            on: {
              "update:options": function ($event) {
                _vm.options = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }